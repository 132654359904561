<template>
  <div>
    <q-form ref="editForm">
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="trainingScenario"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              required
              :editable="editable"
              label="시나리오명"
              name="scenarioName"
              v-model="trainingScenario.scenarioName" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
            <c-plant
              required
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="trainingScenario.plantCd" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
            <c-checkbox
              :editable="editable"
              :isFlag="true"
              label="사용여부"
              name="useFlag"
              v-model="trainingScenario.useFlag"
            />
          </div>
        </template>
      </c-card>
      <c-table
        ref="table"
        class="q-mt-sm"
        title="시나리오 상황"
        :merge="grid.merge"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="trainingScenario.situations"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable"
        selection="multiple"
        rowKey="sopFireFightingTraningScenarioSituationId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn 
              v-if="editable" 
              :showLoading="false" 
              label="LBLADD" 
              icon="add" 
              @btnClicked="addTarget" />
            <c-btn 
              v-if="editable" 
              :disabled="!trainingScenario.situations || trainingScenario.situations.length === 0"
              label="제외" 
              icon="remove" 
              @btnClicked="deleteTarget" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <div>
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                v-if="editable"
                label=""
                icon="add"
                color="light-blue"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.stop="addRow(props)">
              </q-btn>
            </q-btn-group>
          </div>
          <c-textarea-column
            :editable="editable"
            :col="col"
            :props="props"
            @datachange="datachange(props, col)"
          />
        </template>
      </c-table>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingTrainingScenarioDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingTraningScenarioId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      trainingScenario: {
        sopFireFightingTraningScenarioId: '',  // 소방 훈련 시나리오 일련번호
        plantCd: '',  // 사업장코드
        scenarioName: '',  // 시나리오명
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        situations: [],
        deleteSituations: [],
      },
      grid: {
        merge: [
          { index: 0, colName: 'situationMessage', }
        ],
        columns: [
          {
            name: 'situationMessage',
            field: 'situationMessage',
            label: '상황메시지',
            align: 'left',
            sortable: true,
            style: 'width: 200px',
            type: 'custom',
          },
          {
            name: 'trainingTime',
            field: 'trainingTime',
            label: '훈련시간',
            align: 'left',
            sortable: true,
            style: 'width: 150px',
            type: 'textarea',
          },
          {
            name: 'trainingLocation',
            field: 'trainingLocation',
            label: '훈련위치<br/>(평가관 위치)',
            align: 'left',
            sortable: true,
            style: 'width: 120px',
            type: 'textarea',
          },
          {
            name: 'trainingSituation',
            field: 'trainingSituation',
            label: '훈련상황',
            align: 'left',
            sortable: true,
            style: 'width: 150px',
            type: 'textarea',
          },
          {
            name: 'trainingContents',
            field: 'trainingContents',
            label: '훈련내용<br/>(기대행동)',
            align: 'left',
            sortable: true,
            style: 'width: 350px',
            type: 'textarea',
          },
        ],
        height: '500px',
      },
      editable: true,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = this.contentHeight - 200;
      if (height < 600) {
        height = 600
      }
      this.grid.height = String(height) + 'px'
    }
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingTraningScenarioId)
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingTrainingScenario.get.url
      this.saveUrl = transactionConfig.fft.fireFightingTrainingScenario.update.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingTraningScenarioId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.trainingScenario, _result.data)
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        // nothing
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    addRow(props) {
      this.trainingScenario.situations.splice(props.rowIndex, 0, {
        sopFireFightingTraningScenarioSituationId: uid(),  // 소방 훈련 시나리오 상황 일련번호
        sopFireFightingTraningScenarioId: this.popupParam.sopFireFightingTraningScenarioId,  // 소방 훈련 시나리오 일련번호
        situationMessage: props.row.situationMessage,  // 상황메시지
        trainingTime: '',  // 훈련시간
        trainingLocation: '',  // 훈련위치
        trainingSituation: '',  // 훈련상황
        trainingContents: '',  // 훈련내용
        regUserId: this.$store.getters.user.userId,  // 등록자
        editFlag: 'C'
      })
    },
    addTarget() {
      this.trainingScenario.situations.splice(0, 0, {
        sopFireFightingTraningScenarioSituationId: uid(),  // 소방 훈련 시나리오 상황 일련번호
        sopFireFightingTraningScenarioId: this.popupParam.sopFireFightingTraningScenarioId,  // 소방 훈련 시나리오 일련번호
        situationMessage: '',  // 상황메시지
        trainingTime: '',  // 훈련시간
        trainingLocation: '',  // 훈련위치
        trainingSituation: '',  // 훈련상황
        trainingContents: '',  // 훈련내용
        regUserId: this.$store.getters.user.userId,  // 등록자
        editFlag: 'C'
      })
    },
    deleteTarget() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.trainingScenario.deleteSituations) this.trainingScenario.deleteSituations = []
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.trainingScenario.deleteSituations, { sopFireFightingTraningScenarioSituationId: item.sopFireFightingTraningScenarioSituationId }) === -1
            && item.editFlag !== 'C') {
              this.trainingScenario.deleteSituations.push(item)
            }
          this.trainingScenario.situations = this.$_.reject(this.trainingScenario.situations, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFightingTrainingScenario.update.url
        this.mappingType = 'PUT'
      } else {
        this.saveUrl = transactionConfig.fft.fireFightingTrainingScenario.insert.url
        this.mappingType = 'POST'
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.trainingScenario.regUserId = this.$store.getters.user.userId;
              this.trainingScenario.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopFireFightingTraningScenarioId = result.data
      this.getDetail();
    },
  }
};
</script>